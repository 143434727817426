// Changes to the original file:
// - Converted to SCSS automatically
// - Added background-size: 320px 96px whenever there's a background-image 32px.png so that it scales
// - Switched 32px.png to the orangehill proton theme, manually switched colours in GIMP
// - Added these comments

.jstree-node {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
    white-space: nowrap;
}
.jstree-node.category > a {
    font-weight: 600;
}
.jstree-children {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
}
.jstree-container-ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
}
.jstree-anchor {
    display: inline-block;
    color: #000;
    white-space: nowrap;
    padding: 0 4px 0 1px;
    margin: 0;
    vertical-align: top;
    text-decoration: none;
    color: inherit;
    &:focus {
        outline: 0;
    }
    &:link {
        text-decoration: none;
        color: inherit;
    }
    &:visited {
        text-decoration: none;
        color: inherit;
    }
    &:hover {
        text-decoration: none;
        color: inherit;
    }
    &:active {
        text-decoration: none;
        color: inherit;
    }
    > .jstree-themeicon {
        margin-right: 2px;
    }
    > .jstree-themeicon-hidden {
        display: none;
    }
}
.jstree-icon {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    text-align: center;
    &:empty {
        display: inline-block;
        text-decoration: none;
        margin: 0;
        padding: 0;
        vertical-align: top;
        text-align: center;
    }
}
.jstree-ocl {
    cursor: pointer;
}
.jstree-leaf {
    > .jstree-ocl {
        cursor: default;
    }
}
.jstree {
    .jstree-open {
        > .jstree-children {
            display: block;
        }
    }
    .jstree-closed {
        > .jstree-children {
            display: none;
        }
    }
    .jstree-leaf {
        > .jstree-children {
            display: none;
        }
    }
}
.jstree-no-icons {
    .jstree-themeicon {
        display: none;
    }
}
.jstree-hidden {
    display: none;
}
.jstree-rtl {
    .jstree-anchor {
        padding: 0 1px 0 4px;
        > .jstree-themeicon {
            margin-left: 2px;
            margin-right: 0;
        }
    }
    .jstree-node {
        margin-left: 0;
    }
    .jstree-container-ul {
        > .jstree-node {
            margin-right: 0;
        }
    }
}
.jstree-wholerow-ul {
    position: relative;
    display: inline-block;
    min-width: 100%;
    .jstree-leaf {
        > .jstree-ocl {
            cursor: pointer;
        }
    }
    .jstree-anchor {
        position: relative;
    }
    .jstree-icon {
        position: relative;
    }
    .jstree-wholerow {
        width: 100%;
        cursor: pointer;
        position: absolute;
        left: 0;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}
.vakata-context {
    display: none;
    margin: 0;
    padding: 2px;
    position: absolute;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999;
    ul {
        margin: 0;
        padding: 2px;
        position: absolute;
        background: #f5f5f5;
        border: 1px solid #979797;
        box-shadow: 2px 2px 2px #999;
        list-style: none;
        left: 100%;
        margin-top: -2.7em;
        margin-left: -4px;
    }
    .vakata-context-right {
        ul {
            left: auto;
            right: 100%;
            margin-left: auto;
            margin-right: -4px;
        }
    }
    li {
        list-style: none;
        display: inline;
        > a {
            display: block;
            padding: 0 2em;
            text-decoration: none;
            width: auto;
            color: #000;
            white-space: nowrap;
            line-height: 2.4em;
            text-shadow: 1px 1px 0 #fff;
            border-radius: 1px;
            &:hover {
                position: relative;
                background-color: #e8eff7;
                box-shadow: 0 0 2px #0a6aa1;
            }
            &:focus {
                outline: 0;
            }
            > i {
                text-decoration: none;
                display: inline-block;
                width: 2.4em;
                height: 2.4em;
                background: 0 0;
                margin: 0 0 0 -2em;
                vertical-align: top;
                text-align: center;
                line-height: 2.4em;
                &:empty {
                    width: 2.4em;
                    line-height: 2.4em;
                }
            }
            .vakata-contextmenu-sep {
                display: inline-block;
                width: 1px;
                height: 2.4em;
                background: #fff;
                margin: 0 0.5em 0 0;
                border-left: 1px solid #e2e3e3;
            }
        }
        > a.vakata-context-parent {
            background-image: url(data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==);
            background-position: right center;
            background-repeat: no-repeat;
        }
    }
    .vakata-context-hover {
        > a {
            position: relative;
            background-color: #e8eff7;
            box-shadow: 0 0 2px #0a6aa1;
        }
    }
    .vakata-context-separator {
        > a {
            background: #fff;
            border: 0;
            border-top: 1px solid #e2e3e3;
            height: 1px;
            min-height: 1px;
            max-height: 1px;
            padding: 0;
            margin: 0 0 0 2.4em;
            border-left: 1px solid #e0e0e0;
            text-shadow: 0 0 0 transparent;
            box-shadow: 0 0 0 transparent;
            border-radius: 0;
            &:hover {
                background: #fff;
                border: 0;
                border-top: 1px solid #e2e3e3;
                height: 1px;
                min-height: 1px;
                max-height: 1px;
                padding: 0;
                margin: 0 0 0 2.4em;
                border-left: 1px solid #e0e0e0;
                text-shadow: 0 0 0 transparent;
                box-shadow: 0 0 0 transparent;
                border-radius: 0;
            }
        }
    }
    .vakata-contextmenu-disabled {
        a {
            color: silver;
            background-color: transparent;
            border: 0;
            box-shadow: 0 0 0;
            &:hover {
                color: silver;
                background-color: transparent;
                border: 0;
                box-shadow: 0 0 0;
            }
        }
    }
    .vakata-contextmenu-shortcut {
        font-size: 0.8em;
        color: silver;
        opacity: 0.5;
        display: none;
    }
}
.vakata-context-rtl {
    ul {
        left: auto;
        right: 100%;
        margin-left: auto;
        margin-right: -4px;
    }
    li {
        > a.vakata-context-parent {
            background-image: url(data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7);
            background-position: left center;
            background-repeat: no-repeat;
        }
        > a {
            > i {
                margin: 0 -2em 0 0;
            }
            .vakata-contextmenu-sep {
                margin: 0 0 0 0.5em;
                border-left-color: #fff;
                background: #e2e3e3;
            }
        }
    }
    .vakata-context-separator {
        > a {
            margin: 0 2.4em 0 0;
            border-left: 0;
            border-right: 1px solid #e2e3e3;
        }
    }
    .vakata-context-left {
        ul {
            right: auto;
            left: 100%;
            margin-left: -4px;
            margin-right: auto;
        }
    }
}
#jstree-marker {
    position: absolute;
    top: 0;
    left: 0;
    margin: -5px 0 0 0;
    padding: 0;
    border-right: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
}
#jstree-dnd {
    line-height: 16px;
    margin: 0;
    padding: 4px;
    .jstree-icon {
        display: inline-block;
        text-decoration: none;
        margin: 0 2px 0 0;
        padding: 0;
        width: 16px;
        height: 16px;
    }
    .jstree-copy {
        display: inline-block;
        text-decoration: none;
        margin: 0 2px 0 0;
        padding: 0;
        width: 16px;
        height: 16px;
        margin: 0 2px;
    }
    .jstree-ok {
        background: green;
    }
    .jstree-er {
        background: red;
    }
}
.jstree-default {
    .jstree-node {
        background-repeat: no-repeat;
        background-color: transparent;
        min-height: 24px;
        line-height: 24px;
        margin-left: 24px;
        min-width: 24px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-position: -292px -4px;
        background-repeat: repeat-y;
    }
    .jstree-icon {
        background-repeat: no-repeat;
        background-color: transparent;
        width: 24px;
        height: 24px;
        line-height: 24px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        &:empty {
            width: 24px;
            height: 24px;
            line-height: 24px;
        }
    }
    .jstree-anchor {
        transition: background-color 0.15s, box-shadow 0.15s;
        line-height: 24px;
        height: 24px;
        > .jstree-undetermined {
            background-position: -196px -4px;
            &:hover {
                background-position: -196px -36px;
            }
        }
    }
    .jstree-wholerow {
        transition: background-color 0.15s, box-shadow 0.15s;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 24px;
    }
    .jstree-hovered {
        background: #e7f4f9;
        border-radius: 2px;
        box-shadow: inset 0 0 1px #ccc;
    }
    .jstree-clicked {
        background: #beebff;
        border-radius: 2px;
        box-shadow: inset 0 0 1px #999;
    }
    .jstree-no-icons {
        .jstree-anchor {
            > .jstree-themeicon {
                display: none;
            }
        }
    }
    .jstree-disabled {
        background: 0 0;
        color: #666;
        background: 0 0;
        > .jstree-icon {
            opacity: 0.8;
            filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
            filter: gray;
            -webkit-filter: grayscale(100%);
        }
    }
    .jstree-disabled.jstree-hovered {
        background: 0 0;
        box-shadow: none;
        background: 0 0;
    }
    .jstree-disabled.jstree-clicked {
        background: #efefef;
        background: #efefef;
    }
    .jstree-search {
        font-style: italic;
        color: #8b0000;
        font-weight: 700;
    }
    .jstree-no-checkboxes {
        .jstree-checkbox {
            display: none !important;
        }
    }
    > .jstree-striped {
        min-width: 100%;
        display: inline-block;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==)
            left top repeat;
        background-size: auto 48px;
    }
    > .jstree-wholerow-ul {
        .jstree-hovered {
            background: 0 0;
            box-shadow: none;
            border-radius: 0;
        }
        .jstree-clicked {
            background: 0 0;
            box-shadow: none;
            border-radius: 0;
        }
    }
    .jstree-wholerow-hovered {
        background: #e7f4f9;
    }
    .jstree-wholerow-clicked {
        background: #beebff;
        background: -webkit-linear-gradient(top, #beebff 0, #a8e4ff 100%);
        background: linear-gradient(to bottom, #beebff 0, #a8e4ff 100%);
    }
    .jstree-last {
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -132px -4px;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -100px -4px;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -68px -4px;
        }
    }
    .jstree-themeicon {
        background-position: -260px -4px;
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -36px -4px;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: -4px -4px;
            }
        }
    }
    .jstree-checkbox {
        background-position: -164px -4px;
        &:hover {
            background-position: -164px -36px;
        }
    }
    .jstree-checked {
        > .jstree-checkbox {
            background-position: -228px -4px;
            &:hover {
                background-position: -228px -36px;
            }
        }
    }
    .jstree-checkbox-disabled {
        opacity: 0.8;
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
        filter: gray;
        -webkit-filter: grayscale(100%);
    }
    .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0;
    }
    > .jstree-container-ul {
        .jstree-loading {
            > .jstree-ocl {
                background: url('~images/throbber.gif') center center no-repeat;
            }
        }
        > .jstree-node {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .jstree-file {
        background: url('~images/32px.png') -100px -68px no-repeat;
    }
    .jstree-folder {
        background: url('~images/32px.png') -260px -4px no-repeat;
    }
}
.jstree-default.jstree-checkbox-no-clicked {
    .jstree-clicked {
        background: 0 0;
        box-shadow: none;
    }
    .jstree-clicked.jstree-hovered {
        background: #e7f4f9;
    }
    > .jstree-wholerow-ul {
        .jstree-wholerow-clicked {
            background: 0 0;
        }
        .jstree-wholerow-clicked.jstree-wholerow-hovered {
            background: #e7f4f9;
        }
    }
}
.jstree-default.jstree-rtl {
    .jstree-node {
        margin-right: 24px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==);
        background-position: 100% 1px;
        background-repeat: repeat-y;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==);
    }
    .jstree-last {
        background: 0 0;
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -132px -36px;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -100px -36px;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -68px -36px;
        }
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -36px -36px;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: -4px -36px;
            }
        }
    }
}
.jstree-default.jstree-checkbox-selection {
    .jstree-clicked {
        > .jstree-checkbox {
            background-position: -228px -4px;
            &:hover {
                background-position: -228px -36px;
            }
        }
    }
}
#jstree-dnd.jstree-default {
    line-height: 24px;
    padding: 0 4px;
    .jstree-ok {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: -4px -68px;
    }
    .jstree-er {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: -36px -68px;
    }
    i {
        background: 0 0;
        width: 24px;
        height: 24px;
        line-height: 24px;
    }
}
.jstree-default-small {
    .jstree-node {
        min-height: 18px;
        line-height: 18px;
        margin-left: 18px;
        min-width: 18px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-position: -295px -7px;
        background-repeat: repeat-y;
    }
    .jstree-anchor {
        line-height: 18px;
        height: 18px;
        > .jstree-undetermined {
            background-position: -199px -7px;
            &:hover {
                background-position: -199px -39px;
            }
        }
    }
    .jstree-icon {
        width: 18px;
        height: 18px;
        line-height: 18px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        &:empty {
            width: 18px;
            height: 18px;
            line-height: 18px;
        }
    }
    .jstree-wholerow {
        height: 18px;
    }
    .jstree-last {
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -135px -7px;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -103px -7px;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -71px -7px;
        }
    }
    .jstree-themeicon {
        background-position: -263px -7px;
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -39px -7px;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: -7px -7px;
            }
        }
    }
    .jstree-disabled {
        background: 0 0;
    }
    .jstree-disabled.jstree-hovered {
        background: 0 0;
    }
    .jstree-disabled.jstree-clicked {
        background: #efefef;
    }
    .jstree-checkbox {
        background-position: -167px -7px;
        &:hover {
            background-position: -167px -39px;
        }
    }
    .jstree-checked {
        > .jstree-checkbox {
            background-position: -231px -7px;
            &:hover {
                background-position: -231px -39px;
            }
        }
    }
    .jstree-checkbox-disabled {
        opacity: 0.8;
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
        filter: gray;
        -webkit-filter: grayscale(100%);
    }
    > .jstree-striped {
        background-size: auto 36px;
    }
    .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0;
    }
    > .jstree-container-ul {
        .jstree-loading {
            > .jstree-ocl {
                background: url('~images/throbber.gif') center center no-repeat;
            }
        }
        > .jstree-node {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .jstree-file {
        background: url('~images/32px.png') -103px -71px no-repeat;
    }
    .jstree-folder {
        background: url('~images/32px.png') -263px -7px no-repeat;
    }
}
.jstree-default-small.jstree-rtl {
    .jstree-node {
        margin-right: 18px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==);
        background-position: 100% 1px;
        background-repeat: repeat-y;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==);
    }
    .jstree-last {
        background: 0 0;
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -135px -39px;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -103px -39px;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -71px -39px;
        }
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -39px -39px;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: -7px -39px;
            }
        }
    }
}
.jstree-default-small.jstree-checkbox-selection {
    .jstree-clicked {
        > .jstree-checkbox {
            background-position: -231px -7px;
            &:hover {
                background-position: -231px -39px;
            }
        }
    }
}
#jstree-dnd.jstree-default-small {
    line-height: 18px;
    padding: 0 4px;
    .jstree-ok {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: -7px -71px;
    }
    .jstree-er {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: -39px -71px;
    }
    i {
        background: 0 0;
        width: 18px;
        height: 18px;
        line-height: 18px;
    }
}
.jstree-default-large {
    .jstree-node {
        min-height: 32px;
        line-height: 32px;
        margin-left: 32px;
        min-width: 32px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-position: -288px 0;
        background-repeat: repeat-y;
    }
    .jstree-anchor {
        line-height: 32px;
        height: 32px;
        > .jstree-undetermined {
            background-position: -192px 0;
            &:hover {
                background-position: -192px -32px;
            }
        }
    }
    .jstree-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        &:empty {
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
    }
    .jstree-wholerow {
        height: 32px;
    }
    .jstree-last {
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -128px 0;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -96px 0;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -64px 0;
        }
    }
    .jstree-themeicon {
        background-position: -256px 0;
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -32px 0;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: 0 0;
            }
        }
    }
    .jstree-disabled {
        background: 0 0;
    }
    .jstree-disabled.jstree-hovered {
        background: 0 0;
    }
    .jstree-disabled.jstree-clicked {
        background: #efefef;
    }
    .jstree-checkbox {
        background-position: -160px 0;
        &:hover {
            background-position: -160px -32px;
        }
    }
    .jstree-checked {
        > .jstree-checkbox {
            background-position: -224px 0;
            &:hover {
                background-position: -224px -32px;
            }
        }
    }
    .jstree-checkbox-disabled {
        opacity: 0.8;
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
        filter: gray;
        -webkit-filter: grayscale(100%);
    }
    > .jstree-striped {
        background-size: auto 64px;
    }
    .jstree-themeicon-custom {
        background-color: transparent;
        background-image: none;
        background-position: 0 0;
    }
    > .jstree-container-ul {
        .jstree-loading {
            > .jstree-ocl {
                background: url('~images/throbber.gif') center center no-repeat;
            }
        }
        > .jstree-node {
            margin-left: 0;
            margin-right: 0;
        }
    }
    .jstree-file {
        background: url('~images/32px.png') -96px -64px no-repeat;
    }
    .jstree-folder {
        background: url('~images/32px.png') -256px 0 no-repeat;
    }
}
.jstree-default-large.jstree-rtl {
    .jstree-node {
        margin-right: 32px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==);
        background-position: 100% 1px;
        background-repeat: repeat-y;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==);
    }
    .jstree-last {
        background: 0 0;
        background: 0 0;
    }
    .jstree-open {
        > .jstree-ocl {
            background-position: -128px -32px;
        }
    }
    .jstree-closed {
        > .jstree-ocl {
            background-position: -96px -32px;
        }
    }
    .jstree-leaf {
        > .jstree-ocl {
            background-position: -64px -32px;
        }
    }
    > .jstree-no-dots {
        .jstree-node {
            background: 0 0;
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: -32px -32px;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: 0 -32px;
            }
        }
    }
}
.jstree-default-large.jstree-checkbox-selection {
    .jstree-clicked {
        > .jstree-checkbox {
            background-position: -224px 0;
            &:hover {
                background-position: -224px -32px;
            }
        }
    }
}
#jstree-dnd.jstree-default-large {
    line-height: 32px;
    padding: 0 4px;
    .jstree-ok {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: 0 -64px;
    }
    .jstree-er {
        background-image: url('~images/32px.png');
        background-size: 320px 96px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: -32px -64px;
    }
    i {
        background: 0 0;
        width: 32px;
        height: 32px;
        line-height: 32px;
    }
}
@media (max-width: 768px) {
    #jstree-dnd.jstree-dnd-responsive {
        line-height: 40px;
        font-weight: 700;
        font-size: 1.1em;
        text-shadow: 1px 1px #fff;
        > i {
            background: 0 0;
            width: 40px;
            height: 40px;
        }
        > .jstree-ok {
            background-image: url('~images/32px.png');
            background-position: 0 -200px;
            background-size: 120px 240px;
        }
        > .jstree-er {
            background-image: url('~images/32px.png');
            background-position: -40px -200px;
            background-size: 120px 240px;
        }
    }
    #jstree-marker.jstree-dnd-responsive {
        border-left-width: 10px;
        border-top-width: 10px;
        border-bottom-width: 10px;
        margin-top: -10px;
    }
    .jstree-default-responsive {
        .jstree-icon {
            background-image: url('~images/32px.png');
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-image: url('~images/32px.png');
            background-size: 120px 240px;
            &:empty {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
        }
        .jstree-node {
            background: 0 0;
            min-height: 40px;
            line-height: 40px;
            margin-left: 40px;
            min-width: 40px;
            white-space: nowrap;
            background-image: url('~images/32px.png');
            background-size: 120px 240px;
            background-position: -80px 0;
            background-repeat: repeat-y;
            > .jstree-ocl {
                background-image: url('~images/32px.png');
                background-size: 120px 240px;
            }
        }
        .jstree-leaf {
            > .jstree-ocl {
                background: 0 0;
                background: 0 0;
                background-position: -40px -120px;
            }
        }
        .jstree-anchor {
            line-height: 40px;
            height: 40px;
            font-weight: 700;
            font-size: 1.1em;
            text-shadow: 1px 1px #fff;
            > .jstree-undetermined {
                background-position: 0 -120px;
                &:hover {
                    background-position: 0 -120px;
                }
            }
        }
        > .jstree-container-ul {
            > .jstree-node {
                margin-left: 0;
                margin-left: 0;
                margin-right: 0;
            }
        }
        .jstree-ocl {
            background-size: 120px 240px;
        }
        .jstree-themeicon {
            background-size: 120px 240px;
            background-position: -40px -40px;
            background-image: url('~images/32px.png');
            background-size: 120px 240px;
        }
        .jstree-checkbox {
            background-size: 120px 240px;
            background-position: -40px -80px;
            background-image: url('~images/32px.png');
            background-size: 120px 240px;
            &:hover {
                background-position: -40px -80px;
            }
        }
        .jstree-open {
            > .jstree-ocl {
                background-position: 0 0 !important;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: 0 -40px !important;
            }
        }
        .jstree-checked {
            > .jstree-checkbox {
                background-position: 0 -80px;
                &:hover {
                    background-position: 0 -80px;
                }
            }
        }
        > .jstree-striped {
            background: 0 0;
        }
        .jstree-wholerow {
            border-top: 1px solid rgba(255, 255, 255, 0.7);
            border-bottom: 1px solid rgba(64, 64, 64, 0.2);
            background: #ebebeb;
            height: 40px;
        }
        .jstree-wholerow-hovered {
            background: #e7f4f9;
        }
        .jstree-wholerow-clicked {
            background: #beebff;
        }
        .jstree-children {
            .jstree-last {
                > .jstree-wholerow {
                    box-shadow: inset 0 -6px 3px -5px #666;
                }
            }
            .jstree-open {
                > .jstree-wholerow {
                    box-shadow: inset 0 6px 3px -5px #666;
                    border-top: 0;
                }
            }
            .jstree-open + .jstree-open {
                box-shadow: none;
            }
        }
        .jstree-last {
            background: 0 0;
            > .jstree-ocl {
                background-position: -40px -160px;
            }
        }
        .jstree-themeicon-custom {
            background-color: transparent;
            background-image: none;
            background-position: 0 0;
        }
        .jstree-file {
            background: url('~images/32px.png') 0 -160px no-repeat;
            background-size: 120px 240px;
        }
        .jstree-folder {
            background: url('~images/32px.png') -40px -40px no-repeat;
            background-size: 120px 240px;
        }
    }
    .jstree-default-responsive.jstree-rtl {
        .jstree-node {
            margin-left: 0;
            margin-right: 40px;
        }
        .jstree-container-ul {
            > .jstree-node {
                margin-right: 0;
            }
        }
        .jstree-closed {
            > .jstree-ocl {
                background-position: -40px 0 !important;
            }
        }
    }
    .jstree-default-responsive.jstree-checkbox-selection {
        .jstree-clicked {
            > .jstree-checkbox {
                background-position: 0 -80px;
                &:hover {
                    background-position: 0 -80px;
                }
            }
        }
    }
}

.jstree-default {
    .jstree-anchor {
        color: $dark-grey;
        transition: none;
        user-select: none;
    }
    .jstree-hovered {
        border-radius: 0px;
        box-shadow: none;
        background: none;
    }
    &:not(.jstree-dnd-parent) .jstree-hovered {
        color: black;
        background: rgba($blue, 0.1);
        > .jstree-checkbox {
            background-position: -164px -36px;
            &.jstree-undetermined {
                background-position: -196px -36px;
            }
        }
        &.jstree-checked > .jstree-checkbox {
            background-position: -228px -4px !important;
            &.jstree-undetermined {
                background-position: -196px -36px;
            }
        }
    }
}
