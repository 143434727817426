.modal {
    position: fixed;
    top: $topbar-height;
    left: 0;
    bottom: 0;
    right: $smart-group-panel-width;


    @media(max-width: 1100px) {
        &:not(.compact) > article > .sidepanel{
            width: 190px !important;
        }

        &:not(.compact) > article > main {
            width: calc(100% - 190px) !important;

            .dimensions-sidebar {
                width: 190px !important;
                min-width: 190px !important;
            }

            .property-filters .item-property-filters {
                left: 190px;
            }
        }
    }

    .overlay {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba($navy, 0.8);
        z-index: $z-overlay;
        pointer-events: none;
    }

    & > article {
        margin: 32px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        z-index: $z-modal;
        overflow: hidden;
        box-shadow: 0px 2px 2px rgba(black, 0.2);

        $header-height: 50px;
        $sidepanel-width: 275px;
        $sidepanel-footer-height: 50px;

        & > header {
            position: absolute;
            top: 0; left: 0; right: 0;
            height: $header-height;
            line-height: $header-height;
            border-bottom: $border;
            z-index: $z-header;

            h1 {
                display: inline-block;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 700;
                font-size: 11px;
                color: $dark-grey;
                padding-left: 20px;
            }

            .button-modal-close {
                float: right;
                text-align: center;
                cursor: pointer;
                position: relative;
                width: 50px;
                font-weight: 600;
                font-size: 40px;
                fill: $dark-grey;
                border-left: $border;

                &:hover {
                    fill: $blue;
                }

                svg {
                    position: relative;
                    top: -2px;
                    width: 25px;
                    height: 25px;
                }
            }
        }

        & > .sidepanel {
            position: absolute;
            right: 0; bottom: 0;
            top: $header-height;
            width: $sidepanel-width;

            & > main {
                position: absolute;
                top: 0; left: 0;
                bottom: $sidepanel-footer-height; right: 0;
            }

            & > footer {
                position: absolute;
                bottom: 0;
                height: $sidepanel-footer-height;
                left: 0;
                right: 0;
                background: rgba(green, 0.1);

                .button-reset, .button-save {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font-size: 13px;
                    text-align: center;
                    line-height: 50px;
                    transition: all 0.1s ease-in-out;
                }

                .button-reset {
                    position: absolute;
                    top: 0; bottom: 0; left: 0;
                    right: 66.66666666%;
                    background: $navy;
                    font-weight: 600;
                    color: $white;
                    cursor: pointer;

                    &:hover {
                        background: $light-navy;
                    }
                }

                .button-save {
                    position: absolute;
                    top: 0; bottom: 0; right: 0;
                    left: 33.3333333%;
                    font-weight: 700;
                    background: $yellow;
                    cursor: pointer;

                    &.unsaved{
                        font-style: italic;
                        &:after {
                            content: '*';
                        }
                    }

                    &:hover {
                        background: $light-yellow;
                    }
                }
            }
        }

        & > main {
            position: absolute;
            right: 0; bottom: 0;
            left: 0;
            top: $header-height;
            // padding: 30px;
            background: $mid-grey;
            // overflow: hidden;
            background: $white;
            width: calc(100% - #{$sidepanel-width});
        }
    }
}
