
.query-modifiers {
    border-top: $border;
    background: $lighter-grey;

    .query-modifier {
        position: relative;
        padding: 10px 15px;

        &:not(:last-child) {
            border-bottom: $border;
        }

        label {
            transition: opacity 0.2s;
            opacity: 0.5;
            font-size: 12px;
            color: $dark-grey;
        }
        &:hover { label {
            opacity: 1;
        }}
    }

    .query-modifier-currency {
        height: $currency-switcher-height;
        border-top: $border;
        box-shadow: inset 0px 1px 1px rgba(black, 0.04);

        label {
            position: absolute;
            top: 10px;
            left: 20px;
            right: 20px;
        }

        i {
            cursor: pointer;
            position: absolute;
            right: 3px;
            top: 37px;
            z-index: 1;
            background-color: white;
            display: block !important;
        }

        select {
            position: absolute;
            top: 23px;
            left: 1px;
            right: 0px;
            width: 100%;
            color: #777;
            border: 1px solid $border-color;
            background-color: white;
            height: 44px;
            font-size: 14px;
            font-weight: 500;
            padding: 5px;
        }
    }
}
