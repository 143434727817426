#app.route-metrics.disable-segments-metrics-page {
    #view {
        right: 0;
    }
    #sidebar {
        .smart-groups-panel {
            display: none;
        }
        .toggle-sidebar {
            display: none;
        }
        .calendar-popup:after {
            right: 100px;
        }
    }
}

.view-metrics {
    $header-height: 160px;
    $metrics-funnel-node-actions-height: 30px;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $body-background-color;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    & > .loadable {
        background-color: $body-background-color;
        opacity: 0;
        &.loading {
            opacity: 1;
        }
        &:after {
            z-index: $z-modal;
        }
        &:before {
            z-index: $z-modal;
        }
    }

    &.drop-mode .drag-and-drop-zone {
        height: 100%;
        width: 100%;
    }

    .drag-and-drop-zone {
        position: absolute;
        height: 0;
        width: 0;
        z-index: 6;
        opacity: 0.8;
        background: $white;
    }

    > .ui-tabs-with-menu {
        flex: 0;
        z-index: 12;
    }

    > main {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 0;
        z-index: 0;

        > main {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            min-height: 0;

            > main {
                flex: 1;
                display: flex;
                flex-direction: column;
            }

            .dimensions-sidebar .dimensions-sidebar-content .sidebar-properties-menu {
                .sidebar-menu-search-box {
                    height: 42px;
                    i.icon-search {
                        bottom: 7px;
                    }
                }
            }
        }
    }

    .metrics-funnel-breadcrumb-actions-panel {
        display: flex;
        background-color: $white;

        .left-panel {
            display: flex;
            align-items: center;
            border-bottom: $border;
            border-right: $border;
        }
    }

    // This is the top section:
    // - selected filters
    // - group by
    .metrics-funnel-breadcrumb {
        background: $white;

        .collapsed-header-actions {
            max-height: 0px;
            background-color: $body-background-color;
            display: flex;
            justify-content: flex-end;
            padding: 0 2px;
            position: relative;
            overflow: hidden;

            .button-export {
                height: 32px;
            }

            &.transitions {
                transition: max-height 0.3s ease-in-out;
            };

            &.show {
                visibility: visible;
                max-height: 32px;
            }

            .toggle-header-button {
                height: 24px;
                width: 90px;
                position: relative;

                .show-header-button {
                    position: absolute;
                    top: 0;
                    width: 90px;
                    background-color: $white;
                    border-radius: 0 0 4px 4px;

                    user-select: none;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 4px;
                    cursor: pointer;

                    &:hover {
                        span,
                        i {
                            color: $blue;
                        }
                    }

                    span {
                        margin-right: 4px;
                    }

                    span,
                    i {
                        font-size: 11px;
                        font-weight: 400;
                        color: $light-grey;
                    }
                }

            }

            .button-export {
                display: flex;
                align-items: center;
                margin-right: 20px;
            }
        }

        .actions-selection-panel {
            display: flex;
            transition: all 1s ease;

            &.hide {
                max-height: 0px;
                overflow: hidden;
            }

            .right-side {
                border-left: $border;
                border-bottom: $border;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 140px;
                padding: 4px 6px;

                .top-part {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    min-height: 60px;

                    .ui-metric-selector-tree-container {
                        display: flex;
                        height: unset;
                        button.metric-expand {
                            top: 0px;
                            margin-right: 0px;
                        }

                        .ui-metric-selector {
                            max-height: calc(90% - 190px);
                            right: 0px;
                        }
                    }
                }

                .bottom-part {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    min-height: 60px;

                    .secondary-button-action-item {
                        position: relative;
                        height: 22px;
                        min-width: 74px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 4px;
                        padding-right: 8px;
                        padding-left: 10px;
                        color: $mid-grey;
                        text-transform: uppercase;
                        letter-spacing: 0.5px;
                        font-weight: 400;
                        cursor: pointer;
                        margin-bottom: 2px;

                        .simple-action-item-label {
                            font-size: 9px;
                        }

                        &:hover {
                            background-color: $lighter-grey;
                            color: $blue;
                        }

                        &.active {
                            border-color: $blue;
                            color: $blue;

                            i {
                                color: $blue;
                            }
                        }

                        i, i:before {
                            font-size: 10px;
                            margin-left: 4px;
                            margin-right: 0px;
                            margin-bottom: 2px;
                        }

                        .loadable.loading::after {
                            transform: scale(0.5, 0.5);
                        }
                    }

                    .secondary-simple-action-item {
                        cursor: pointer;
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 400;
                        color: $light-grey;
                        border: none;
                        height: 16px;
                        margin-bottom: 4px;

                        &:not(.reversed) i {
                            margin-left: 4px;
                        }

                        &.reverse {
                            flex-direction: row-reverse;

                            i {
                                margin-right: 4px;
                            }
                        }

                        &:hover {
                            color: $blue;
                        }
                    }
                }
            }
        }

        .metrics-funnel-breadcrumb-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
        }

        .funnel-state-actions {
            display: flex;
            justify-content: space-between;

            .funnel-state-metrics-tree {
                padding-left: 10px;
                border-left: $border;
                border-bottom: $border;
            }
        }

        .funnel-state {
            width: 100%;

            .properties-container {
                padding-right: 5px;
            }

            span.no-border {
                button {
                    border:none;
                }
            }

            & > header, .properties-container > header {

                h1, button {
                    display: inline-block;
                    line-height: 15px;
                    height: 15px;
                    font-size: 10px;
                    color: $mid-grey;
                    text-transform: uppercase;
                    font-weight: 600;
                    letter-spacing: 1px;
                    border: none;
                }
                h1 {
                    margin-top: 10px;
                    margin-bottom: 11px;
                }
                button {
                    display: inline-block;
                    font-size: 10px;
                    margin-left: 8px;
                    color: rgba($blue, 0.5);
                    text-decoration: none;
                    &:hover {
                        color: $blue;
                        text-decoration: underline;
                    }
                }
            }

            .ui-pellets ul {
                display: flex;
                flex-wrap: wrap;
                .selection-pebble {
                    user-select: text;
                }

                .selection-pebble-item:not(:first-child)::before {
                    margin-right: 5px;
                    content: '⇢';
                    position: relative;
                    color: darken($border-color, 10%);
                    background: none;
                    top: 2px;
                    left: 2px;
                }
            }
        }

        .buttons-holder {
            flex: 0 0 125px;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-left: $border;

            .export-panel-button {
                margin-top: 6px;
                border: none;
                height: 36px;
                width: 100px;
                flex: 0 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                font-size: 9px;
                color: $mid-grey;
                text-decoration: none;

                span {
                    font-weight: 600;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    width: 40px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    line-height: 1.3;
                }

                &:hover {
                    color: $blue;
                    cursor: pointer;
                }
            }

            .header-hide-button {
                user-select: none;
                height: 25px;
                line-height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 4px;
                cursor: pointer;

                &:hover {
                    span,
                    i {
                        color: $blue;
                    }
                }

                span {
                    margin-right: 4px;
                }

                span,
                i {
                    font-size: 11px;
                    font-weight: 400;
                    color: $light-grey;
                }
            }
        }

        .properties-items {
            width: 100%;
            border-bottom: $border;
            .items-list .ui-pellets .ui-pellet {
                border-radius: 14px;
            }
        }
    }

    .metrics-funnel-node {
        position: relative;
        flex: 1 1 auto;

        $grid-actions-panel-height: 50px;

        .grid-actions-panel {
            height: $grid-actions-panel-height;
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            background-color: $white;
            border-bottom: 1px solid #e6eaef;
        }

        .grid {
            .sortable:after {
                display: none
            }
            position: absolute;
            top: $grid-actions-panel-height + 1px; left: 0; bottom: 0; right: 0;

            .ag-cell {
                user-select: text;
            }

            .cell-column-pinned-total-value {
                user-select: none;
                font-size: 12px;
                color: lighten($body-text-color, 10%) !important;
            }

            .ag-row .item-image-render {
                > span, div {
                    width: 100%;
                    height: 100%;
                }
            }

            .ag-cell[col-id="item_image__left"],
            .ag-header-cell[col-id="item_image__left"] {
                padding: 0;
                padding: 3px;
                border-left-width: 0px;
                border-left-color: white !important;
                position: relative;
            }
            .ag-body-viewport .ag-cell[col-id="item_image__left"] {
                > span {
                    min-width: 50px;
                    max-width: 160px;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 4px;
                    border: 1px solid darken($border-color, 12%);
                    padding: 0;
                }
                > span > div {
                    position: relative;
                    overflow: hidden;
                    background: lighten($border-color, 6%);
                    border: 1.5px solid white;
                    border-radius: 5px;
                    img {
                        background-color: white;
                        position: relative;
                        z-index: 1;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0; left: 0; bottom: 0; right: 0;
                    }
                    &:not(.blank)::before {
                        background: transparent url('~images/spinner.gif') no-repeat;
                        background-position: center center;
                        background-size: 24px;
                    }
                    &.blank {
                        z-index: 2;
                        position: relative;
                        img {
                            display: none;
                        }
                        background-image: url('~images/42logo-background-300.png');
                        background-repeat: no-repeat;
                        background-size: 126px 127px;
                    }
                }
            }

            .ag-pinned-left-header {
                .ag-header-cell-text {
                    font-weight: 600;
                }
                .ag-header-cell[col-id="item_image__left"] {
                    .ag-header-cell-text {
                        color: transparent;
                    }
                }
            }

            .ag-pinned-left-cols-container {
                .ag-row:hover {
                    .ag-cell[col-id^="property"],
                    .ag-cell.pinned-property {
                        span {
                            // color: $blue;
                            // text-decoration-color: lighten($blue, 10%);
                        }
                    }
                }

                .ag-cell[col-id^="property"],
                .ag-cell.pinned-property {
                    span {
                        font-weight: 600;
                        text-decoration: underline;
                        text-decoration-color: rgba($body-text-color, 20%);
                        text-decoration-thickness: 1px;
                        text-underline-offset: 0.1em;
                        word-break: break-word;
                    }
                    &:hover {
                        cursor: pointer;
                        span {
                            color: $blue;
                            text-decoration-color: lighten($blue, 10%);
                        }
                    }
                }
            }
        }
    }
}
