@use 'sass:color';
@import '~styles/utils/variables';

.selection-pebble {
    font-size: 11px;
    color: $dark-grey;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 8px;
    padding-bottom: 1px;
    height: 30px;
    position: relative;
    padding: 0 12px;
    background-color: color.adjust($border-color, $lightness: 7%);
    border: 1px solid color.adjust($border-color, $lightness: -8%);
    border-radius: 14px;
    transition: transform 0.05s ease-out;
    user-select: none;
    overflow: visible;
    box-shadow: inset 0 0 0 1px #fff, 0 1.2px 0px 1.4px rgba($border-color-dark,.08);
    cursor: pointer;

    &.checkbox {
        justify-content: flex-start;
        flex-direction: row-reverse;

        &.selectable.selected {
            background-color: lighten($blue, 2%);
        }

        &.shift-select {
            cursor: pointer;
        }
    }

    .pebble-checkbox {
        margin-right: 8px;
        height: 20px;
        width: 20px;

        input[type="checkbox"] {
            cursor: pointer;
        }

    }

    .pebble-description {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .pebble-description {
        > .pebble-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0.5px;
        }

        // Separator between label & value...
        > .pebble-content > span:not(:last-child)::after {
            content: '';
            border-right: 1px solid color.adjust($border-color, $lightness: -5%);
            box-shadow: 0 0 0 1px #fff;
            position: relative;
            top: 0.5px;
            margin: 0 6px;
            width: 0;
        }

        .selection-pebble-chevron {
            width: 20px;
            i {
                font-size: 12px;
            }
        }

        > .pebble-icon {
            cursor: pointer;
            opacity: 0;
            transform: scale(0.8);
            position: absolute;
            right: -3px - 7px;
            top: -4px - 7px;
            transition: opacity 0.05s ease, transform 0.05s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            display: flex;
            font-size: 14px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            box-shadow: 0 0 0 0 #fff;
            border: 'none';

            &:hover {
                opacity: 1;
                transform: scale(1.1);
            }

            &::before {
                background: #fff;
                border: 0.5px solid #fff;
                margin: 0;
                border-radius: 10px;
            }
        }
        .pebble-label, .pebble-icon {
            white-space: normal;
            line-height: 12px;
        }

        .pebble-label {
            font-weight: 400;
            font-style: italic;
        }

        .pebble-value {
            font-weight: 600;
        }

        .pebble-content span:only-child {
            font-weight: 400;
        }
    }

    &.interactive {
        &:hover {
            background-color: #fff;
        }
        &.selected, &:hover {
            .pebble-icon {
                transform: scale(1);
                opacity: 0.8;
                &:hover { opacity: 1 }
            }
        }
    }

    &:not(.interactive) {
        background-color: #fff;
        box-shadow: inset 0 0 0 1px #fff;
        pointer-events: none;
    }

    &.draggable {
        &:hover {
            cursor: grab;
            transform: scale(1.05);
            z-index: 1;
        }
        &:active {
            cursor: grabbing;
        }
        .pebble-icon {
            cursor: pointer;
            transform: scale(0.5);
        }
    }

    &.selectable {

        &.multi-select {
            border-color: lighten($blue, 10%);
            transform: scale(1.05);
            z-index: 1;
            &:hover:not(.checkbox) {
                cursor: pointer;
                transform: scale(1.05);
                z-index: 1;
            }
        }
        &:hover:not(.checkbox) {
            cursor: pointer;
            z-index: 1;
        }

        .pebble-icon {
            cursor: pointer;
            transform: scale(0.5);
        }

        &.selected {
            background-color: $blue;
            color: $white;
            border-color: $blue;
            .pebble-icon {
                color: $white;
            }
        }

        &.selected.neutral,
        &.selected.neutral:hover,
        &.selectable.selected.neutral:hover {
            background-color: $white;
            color: $blue;
            .pebble-value,
            .pebble-label {
                color: $blue;
            }
            border-color: $blue;
            .pebble-icon {
                color: $blue;
            }
        }

        &.neutral.translucid {
            color: rgba($mid-grey, 0.7);
            border-color: transparent;
            box-shadow: none;

            &:not(:hover) {
                .pebble-label, .pebble-value {
                    color: rgba($mid-grey, 0.7);
                }
            }
        }
    }

    &.pebble-color-blue:not(.dragging) {
        .pebble-icon {
            color: $blue;
        }

        > .pebble-content > span:not(:last-child)::after {
            border-right: 1px solid color.adjust($blue, $lightness: -5%);
        }

        &.draggable:hover,
        &.selectable:hover {
            border-color: $blue;
            box-shadow: 0 0 0 0.75px rgba($blue, 0.5), 0 0 4px 0.4px rgba(0, 0, 0, 0.15);
        }
    }

    &.pebble-color-red:not(.dragging) {
        border-color: $lighter-red;
        color: $red;

        > .pebble-content > span:not(:last-child)::after {
            border-right: 1px solid color.adjust($red, $lightness: -5%);
        }

        .pebble-icon {
            color: $red;
        }

        .pebble-label,
        .pebble-value {
            color: $red;
        }
        &.draggable:hover,
        &.selectable:hover {
            border-color: $red;
            box-shadow: inset 0 0 0 1px #fff, 0 0px 0px 2px rgba($red,.05);
        }
    }

    &.pebble-color-neutral:not(.dragging) {
        color: $dark-grey;

        .pebble-label,
        .pebble-value {
            color: $dark-grey;
        }

        .pebble-icon {
            color: color.adjust($border-color-dark, $lightness: -5%);
        }
        &.draggable:hover,
        &.selectable:hover {
            box-shadow: inset 0 0 0 1px #fff, 0 0 2px 0px rgba($border-color-dark,.5);
            border-color: rgba($border-color-dark, 0.7);
        }
    }
}
