@import '~styles/utils/variables';
@import '~styles/base';

.list-custom-header {
    display: flex;
    flex-direction: column;
    border-bottom: $border;
    background-color: $white;

    .list-header-top {
        display: flex;
        height: 42px;

        .list-menu-search-box {
            height: 42px;
            flex: 1 0 42px;
        }
    }

    .action-button {
        height: 41px;
        width: 42px;
        border-right: $border;
        background: rgba($border-color, 0.1);
        box-shadow: inset 0px 0px 0px 2px $white, 0px 0px 2px 0px rgba(black, 0.05);
        border-bottom: 1px solid #e6eaef;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: darken($border-color, 30%);
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            cursor: pointer;
            i {
                color: $dark-grey;
            }
        }
    }

    .list-header-selection-actions {
        height: 36px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list-header-selection-actions-left,
        .list-header-selection-actions-right {
            display: flex;
            color: $mid-grey;
            font-size: 11px;
            user-select: none;
        }

        .list-header-selection-actions-right .list-header-selection-description {
            color: $light-grey;
            font-style: italic;
        }

        .list-header-selection-actions-right .list-header-selection-mode-toggle {
            display: flex;
            flex-wrap: nowrap;
            align-self: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 8px;
            // height: calc(100% - 5px);
            border-radius: 5px;
            cursor: pointer;
            user-select: none;

            font-size: 11px;
            white-space: nowrap;
            border: 1px solid darken($border-color, 6%);
            border-bottom-width: 1.5px;
            background: $lighter-grey;
            color: $dark-grey;

            &:hover {
                background-color: darken($lighter-grey, 2%);
            }

            &.active {
                border-color: rgba($blue, 0.5);
                background: rgba($blue, 0.1);
                color: $blue;
                position: relative;
                .icon-calendar, .timerange-start::after {
                    color: rgba($blue, 0.7);
                }

                .tooltip-container {
                    position: absolute;
                    top: 24px;
                    z-index: 11;
                    right: 0px;
                    align-items: flex-end;
                    display: none;

                    .triangle {
                        margin-right: 10px;
                    }
                }

                &:hover {
                    .tooltip-container {
                        display: flex;
                    }
                }
            }
        }

        .list-header-selection-action {
            text-decoration: underline;
            font-weight: 500;
            text-decoration-color: rgba($body-text-color, 20%);
            text-decoration-thickness: 1px;
            text-underline-offset: 0.2em;
            word-break: break-word;

            &:hover {
                cursor: pointer;
                color: $blue;
                text-decoration-color: lighten($blue, 10%);
            }
        }

        .list-header-selection-action-select-all {
            display: flex;
            &.with-reset::after {
                content: '|';
                height: 100%;
                margin: 0 4px;
                color: rgba($body-text-color, 20%);
            }
        }

    }

    .list-menu-search-box {
        height: 42px;
        i.icon-search {
            bottom: 7px;
        }
    }

    .filter-input-search {
        background: $white;
        display: flex;
        padding: 2px 6px;
        min-height: 42px;
        flex-flow: row-reverse nowrap;
        justify-content: space-around;
        border-bottom: 1px solid $border-color;
        align-items: stretch;
        flex: 0 1 auto;

        input {
            font-size: 12px;
        }

        &.filtered {
            .icon-clear-filter {
                display: flex;
            }
            .icon-search {
                color: $blue;
            }
            input {
                color: $blue;
            }
        }

        &:hover .icon-search,
        input:focus ~ .icon-search {
            opacity: 0.8;
        }

        &:hover input,
        input:focus {
            @include placeholder {
                color: rgba($dark-grey, 0.8);
            }
        }

        i {
            display: inline-block;
            text-align: center;
            flex: 0;
            color: $mid-grey;
            opacity: 0.5;
            font-size: 14px;
        }

        .icon-search {
            padding-right: 6px;
            font-size: 16px;
            display: flex;
            align-items: center;
        }

        .icon-clear-filter {
            display: none;
            padding-left: 6px;
            cursor: pointer;
            font-size: 14px;
            margin-right: -4px;
            align-items: center;
            &:hover {
                opacity: 0.8;
            }
        }

        input {
            flex: 1;
            display: block;
            border: none;
            padding: 0;
            height: auto;
        }
    }
}

.list-custom-header-advanced {
    display: flex;
    border-bottom: $border;
    background-color: $white;

    .list-header-content {
        width: 100%;
    }

    .list-header-top {
        display: flex;
        height: 42px;

        &.spaced {
            justify-content: space-between;
        }

        .list-menu-search-box {
            flex: 1 0 42px;
        }

        .list-header-title {
            display: flex;
            align-items: center;
            padding: 0 12px;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $blue;
            flex: 1 0 auto;
            user-select: none;
            background-color: $white;
        }

        &.header-title {
            border-bottom: 1px solid #e6eaef;
            padding: 1px;
            background-color: white;
        }

        .list-header-actions {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            border-left: $border;

            .list-header-action-icon:not(:first-child) {
                margin-left: 6px;
            }

            .list-header-action-icon {
                position: relative;
                height: 30px;
                width: 30px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.icon-18 {
                    svg {
                        height: 18px;
                        width: 18px;
                    }
                }

                svg {
                    height: 20px;
                    width: 20px;
                    fill: #899cb3;
                }

                &:hover {
                    background-color: darken(#f6f6f8, 10%);
                    cursor: pointer;
                    svg {
                        fill: $navy;
                    }
                }

                &.active:not(:hover) {
                    background-color: darken(#f6f6f8, 5%);
                    svg {
                        fill: $navy;
                    }
                }

                .tooltip-container {
                    position: absolute;
                    display: flex;
                    align-items: flex-end;
                    flex-wrap: nowrap;
                    width: 140px;
                    z-index: 11;
                    top: 26px;
                    right: 0px;
                    display: none;

                    .triangle {
                        margin-right: 10px;
                    }
                }

                &:not(.hide-tooltip):hover {
                    .tooltip-container {
                        display: flex;
                    }
                }
            }
        }
    }

    .action-button {
        height: 41px;
        width: 42px;
        border-right: $border;
        background: rgba($border-color, 0.1);
        box-shadow: inset 0px 0px 0px 2px $white, 0px 0px 2px 0px rgba(black, 0.05);
        border-bottom: 1px solid #e6eaef;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            color: darken($border-color, 30%);
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            cursor: pointer;
            i {
                color: $dark-grey;
            }
        }
    }

    .list-header-selection-actions {
        border-left: $border;
        border-bottom: 1px solid #e6eaef;
        height: 42px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .list-header-selection-actions-left,
        .list-header-selection-actions-right {
            display: flex;
            color: $mid-grey;
            font-size: 11px;
            user-select: none;
        }

        .list-header-selection-actions-right .list-header-selection-description {
            color: $light-grey;
            font-style: italic;
        }

        .list-header-selection-actions-right .list-header-selection-mode-toggle {
            display: flex;
            flex-wrap: nowrap;
            align-self: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 8px;
            // height: calc(100% - 5px);
            border-radius: 5px;
            cursor: pointer;
            user-select: none;

            font-size: 11px;
            white-space: nowrap;
            border: 1px solid darken($border-color, 6%);
            border-bottom-width: 1.5px;
            background: $lighter-grey;
            color: $dark-grey;

            &:hover {
                background-color: darken($lighter-grey, 2%);
            }

            &.active {
                border-color: rgba($blue, 0.5);
                background: rgba($blue, 0.1);
                color: $blue;
                position: relative;
                .icon-calendar, .timerange-start::after {
                    color: rgba($blue, 0.7);
                }

                .tooltip-container {
                    position: absolute;
                    top: 24px;
                    z-index: 11;
                    right: 0px;
                    align-items: flex-end;
                    display: none;

                    .triangle {
                        margin-right: 10px;
                    }
                }

                &:hover {
                    .tooltip-container {
                        display: flex;
                    }
                }
            }
        }

        .list-header-selection-action {
            text-decoration: underline;
            font-weight: 500;
            text-decoration-color: rgba($body-text-color, 20%);
            text-decoration-thickness: 1px;
            text-underline-offset: 0.2em;
            word-break: break-word;

            &:hover {
                cursor: pointer;
                color: $blue;
                text-decoration-color: lighten($blue, 10%);
            }
        }

        .list-header-selection-action-select-all {
            display: flex;
            &.with-reset::after {
                content: '|';
                height: 100%;
                margin: 0 4px;
                color: rgba($body-text-color, 20%);
            }
        }

    }

    .list-menu-search-box {
        height: 42px;
        i.icon-search {
            bottom: 7px;
        }
    }

    .filter-input-search {
        background: $white;
        display: flex;
        padding: 2px 6px;
        min-height: 42px;
        flex-flow: row-reverse nowrap;
        justify-content: space-around;
        border-bottom: 1px solid $border-color;
        align-items: stretch;
        flex: 0 1 auto;

        input {
            font-size: 12px;
        }

        &.filtered {
            .icon-clear-filter {
                visibility: visible;
            }
            .icon-search {
                color: $blue;
            }
            input {
                color: $blue;
            }
        }

        &:hover .icon-search,
        input:focus ~ .icon-search {
            opacity: 0.8;
        }

        &:hover input,
        input:focus {
            @include placeholder {
                color: rgba($dark-grey, 0.8);
            }
        }

        i {
            display: inline-block;
            text-align: center;
            flex: 0;
            color: $mid-grey;
            opacity: 0.5;
            font-size: 14px;
        }

        .icon-search {
            padding-right: 6px;
            font-size: 16px;
            display: flex;
            align-items: center;
        }

        .icon-clear-filter {
            display: flex;
            visibility: hidden;
            padding-left: 6px;
            cursor: pointer;
            font-size: 14px;
            margin-right: -4px;
            align-items: center;
            &:hover {
                opacity: 0.8;
            }
        }

        input {
            flex: 1;
            display: block;
            border: none;
            padding: 0;
            height: auto;
        }
    }
}



