@use "utils/variables";
@use "utils/mixins";

/**
 * Layout
 *
 * This file should contain styles that determine the general layout of
 * the website. Layouts that are specific to views should go in the
 * appropriate module file.
 */

html {
    position: relative;
    -webkit-overflow-scrolling: touch;
}

html, #app {
    width: 100%;
    height: 100%;
    min-width: 300px;
}

#app {
    margin: 0 auto;
}

$topbar-height: 40px;
$status-message-bar-height: 40px;
$subheader-height: 50px;
$footer-height: 70px;
$smart-group-panel-width: 240px;
$currency-switcher-height: 90px;

#app > header {
    height: $topbar-height;
    width: 100%;
}

#app {
    &.resizing {
        user-select: none !important;
        cursor: ew-resize !important;
    }

    & > .status-message-bar.active {
        position: absolute;
        top: $topbar-height;
        height: $status-message-bar-height;
        left: 0;
        right: 0;
        line-height: 18px;
        z-index: $z-elevated;
    }
    & > main {
        position: absolute;
        top: $topbar-height;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .status-message-bar.active + main {
        position: absolute;
        top: $topbar-height + $status-message-bar-height;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .hide-small {
        @media(max-width: $navbar-collapse-breakpoint) {
            display: none !important;
        }
    }

    .hide-large {
        @media(min-width: $navbar-collapse-breakpoint + 1) {
            display: none !important;
        }
    }
}


$icon-width: 50px;

#app > main {
    #subheader {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        right: $smart-group-panel-width;
        z-index: 0;

        @media (max-width: $navbar-collapse-breakpoint) {
            right: 100px;
            z-index: 1;

            .header-currency-and-datepicker .smart-groups-filter-time {
                .calendar-popup.active {
                    max-height: unset;
                    // box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
                    height: 600px;
                }
            }
        }

        height: $subheader-height;

        .header-smart-groups-panel,
        .header-currency-and-datepicker {
            height: $subheader-height;
        }

        .header-smart-groups-panel {
            border-bottom: $border;
        }

        .header-currency-and-datepicker {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 2px;
            .query-modifier {
                height: 100%;
            }

            .smart-groups-filter-time {
                .smart-group-filter-time-panel .calendar-popup-toggle-button {
                    height: 35px;
                }
            }
        }

        &.two-lines {
            height: $subheader-height * 2;
            display: flex;
            flex-direction: column;
            width: 100%;

            .header-currency-and-datepicker {
                .calendar-popup.active {
                    top: 93px;
                    right: 6px;
                }
            }
        }

        background: $white;
        border-bottom: 1px solid $subheader-border-color;
        line-height: $subheader-height - 2px;
        font-size: 13px;
        color: $dark-grey;

        & > * {
            display: inline-block;
            vertical-align: top;
        }

        .group-name {
            font-weight: 600;
            margin-right: 10px;
            color: rgba($blue, 0.7);
        }

        .natural-language-filter-display {
            flex-grow: 1;
        }

        .query-modifier {
            font-size: 10px;
        }

        .compact-group-select {
            position: relative;
            display: flex;
            height: 100%;
            border-right: none;
            justify-content: flex-start;
            z-index: 1;
            opacity: 1;

            &:not(.edit-mode) {
                padding: 5px 6px;
            }

            .compact-group-select-edit-mode {
                display: flex;
            }

            .compact-group-select-edit-segment {
                position: absolute;
                padding: 4px;
                top: 0;
                display: flex;
                height: 100%;
                width: 100%;
                z-index: 1;

                background-color: $white;
                align-items: center;


                input {
                    height: 34px;
                    border: 1px solid $border-color;
                    border-right: 0px;
                    // border-left: 0px;
                    width: 100%;
                    border-radius: 4px 0 0 4px;
                }
                .compact-group-select-edit-segment-edit-button,
                .compact-group-select-edit-segment-delete-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 34px;
                    flex: 0 0 $icon-width;
                    width: $icon-width;

                    i {
                        font-size: 16px;
                    }
                }


                .compact-group-select-edit-segment-edit-button {
                    display: flex;
                    padding: 4px;
                    align-items: center;
                    border: 1px solid #e6eaef;
                    width: $icon-width;

                    &.accept {
                        background-color: $yellow-background-color;
                        border-color: $yellow-background-color;
                        color: #2c343f;
                        border-radius: 0 4px 4px 0;
                    }

                    &.cancel {
                        margin-left: 8px;
                        border-radius: 4px;
                    }

                }

                .compact-group-select-edit-segment-delete-button {
                    padding: 4px;
                    width: $icon-width;
                    border-radius: 4px;
                    border: 1px solid #e6eaef;
                    margin-right: 8px;
                    background-color: darken(#de350b, 5%);
                    color: $white;
                    border-color: darken(#de350b, 5%);
                    opacity: 1;

                    i {
                        opacity: unset;
                    }
                }
            }

            .compact-group-select-segments {
                display: flex;
                height: 100%;
                width: 100%;
                position: relative;
                box-shadow: 0px 0px 0px 0px white;
                transition: box-shadow 0.15s ease-out;

                &.shadow {
                    transition: box-shadow 0s;
                    box-shadow: 0px 0px 50px 0px $yellow-background-color;
                }

                .property-filters-descriptor-popup {
                    display: none;
                    position: absolute;
                    overflow: hidden;
                    left: 0;
                    top: 39px;
                    width: 268px;
                    align-items: center;
                    flex-wrap: wrap;
                    background: white;
                    border-radius: 0 4px 4px 4px;
                    border: 1px solid $blue;
                    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

                    .property-filters-descriptor-content {
                        cursor: pointer;
                        padding-left: 8px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        &:not(:first-of-type) {
                            border-top: 1px solid #eceff3;
                        }

                        &.selected {
                            background-color: $blue;
                            color: $white;
                        }


                    }
                }

                &.descriptor-popup-active .property-filters-descriptor-popup {
                    display: flex;
                    flex-direction: column;
                }

            }


            .compact-group-select-dropdown {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                height: 100%;
                width: 100%;

                select {
                    width: 100%;
                    height: 40px;
                    padding-right: 6px;
                    padding-left: 6px;
                    background-color: $white;
                    display: flex;
                    // border: 1px solid $border-color;
                    border: 1px solid #d3dae3;
                    font-size: 12px;
                    border-bottom-width: 1.5px;
                    // cursor: pointer;
                    text-overflow: ellipsis;
                    border-right: 0px;
                    border-left: 0px;
                    appearance: none;
                    background: url('~images/chevron-down.svg') center / contain;
                    background-repeat: no-repeat;
                    background-position: right 0.7rem top 50%;
                    background-size: 0.50rem auto;
                }

                &.icon-selector {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 $icon-width;

                    &.edit-button {
                        flex: 0 0 35px;
                        position: relative;

                        .segments-edit-items-container {
                            border-radius: 4px 4px 4px 0px;
                            position: absolute;
                            top: 39px;
                            right: 0;

                            >:not(:last-child) {
                                border-bottom: 1px solid lighten($navy, 10%);
                            }
                        }

                        .segments-edit-item {
                            height: 46px;
                            width: 140px;
                            padding-left: 20px;
                            display: flex;
                            align-items: center;
                            background: $navy;
                            color: #fff;
                            letter-spacing: 1px;
                            font-size: 11px;
                            font-weight: 700;

                            &:first-child {
                                border-radius: 5px 0px 0px 0px;
                            }

                            &:last-child {
                                border-radius: 0px 0px 5px 5px;
                            }

                            &:hover {
                                cursor: pointer;
                                color: $yellow;
                                background: lighten($navy, 10%);
                            }

                            &.delete {
                                color: $light-red;

                                &:hover {
                                    color: $white;
                                    background-color: $red;
                                }
                            }
                        }
                    }

                    &.active .icon-new-segment,
                    &.active .icon-selector-funnel,
                    &.active .icon-selector-edit {
                        background-color: $blue;
                        color: $white;
                        border-color: $blue;
                    }

                    .icon-new-segment,
                    .icon-selector-funnel,
                    .icon-selector-edit {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        user-select: none;
                        font-size: 11px;
                        white-space: nowrap;
                        border-radius: 5px;
                        border: 1px solid #d3dae3;
                        border-bottom-width: 1.5px;
                        background: $lighter-grey;
                        color: rgba(darken($border-color, 25%), 1);

                        i {
                            font-size: 16px;
                        }
                    }

                    .icon-new-segment {
                        width: $icon-width;
                        margin-right: 8px;
                    }

                    .icon-selector-edit {
                        width: 35px;
                        border-radius: 0px 5px 5px 0px;
                    }

                    .icon-selector-funnel {
                        width: $icon-width;
                        border-radius: 5px 0 0 5px;

                        &.opened {
                            border-bottom-left-radius: 0;
                        }
                    }

                    &.active .icon-selector-edit {
                        background-color: $navy;
                        border-color: $navy;
                        border-radius: 0px 5px 0px 0px;
                        .icon-pencil {
                            background-color: $navy;
                        }
                    }
                }

                &.segments {
                    position: relative;
                }
            }
        }

        .currency-switcher-select,
        .compact-group-select {
            align-items: flex-start;
            cursor: pointer;
            width: 100%;
            // opacity: 0.7;

            &:hover {
                opacity: 1;
            }

            .select-title {
                width: 100%;
                line-height: 10px;
                font-size: 10px;
                margin-right: 10px;
                display: inline-flex;
                align-items: center;
                text-transform: capitalize;
                white-space: nowrap;
                user-select: none;
            }
        }

        .currency-switcher-select {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 3px 6px;
            border-right: none;
            justify-content: space-between;

            &.small-selector {
                padding: 5px 6px;
                justify-content: center;
                .currency-switcher-dropdown {
                    height: 35px;
                    border-radius: 5px;
                    cursor: pointer;
                    user-select: none;
                    font-size: 11px;
                    border: 1px solid #d3dae3;
                    border-bottom-width: 1.5px;
                    background: $lighter-grey;
                    color: $dark-grey;

                    select {
                        height: 100%;
                        padding-left: 6px;
                        appearance: none;
                        background: url('~images/chevron-down.svg') center / contain;
                        background-repeat: no-repeat;
                        background-position: right 0.6rem top 50%;
                        background-size: 0.50rem auto;
                        border: none;
                    }
                }
            }

            .currency-switcher-dropdown {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: relative;

                i {
                    cursor: pointer;
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    z-index: 1;
                    background-color: $white;
                    height: 16px;
                    line-height: 16px;
                }

                select {
                    min-width: 75px;
                    height: 20px;
                    background-color: $white;
                    border: 1px solid $border-color;
                    font-size: 12px;
                    border-radius: 4px;
                    text-overflow: ellipsis;
                    cursor: pointer;
                }
            }
        }

        .label {
            color: $dark-grey;
        }
    }

    #view {

        transform: translateZ(0);
        position: absolute;
        left: 0; bottom: 0;
        top: $subheader-height;
        overflow: auto;
        right: $smart-group-panel-width;
        @media (max-width: $navbar-collapse-breakpoint)
        {
            &.two-lines {
                top: $subheader-height * 2;
            }
            right: 0;
        }

        &.full-view {
            right: 0;
        }

        &.sidebar-animating {
            transition: right 0.3s ease;
        }

    }

    #sidebar {
        position: absolute;
        top: 0; right: 0; bottom: 0;
        width: $smart-group-panel-width;

        & > header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $subheader-height;
            // border-bottom: 1px solid $subheader-border-color;
            border-left: $border;
            border-bottom: 1px solid $subheader-border-color;
        }

        .sidebar-content {
            transition: transform 0.3s ease;
        }

        .hide-sidebar-content {
            transform: translateX($smart-group-panel-width);
        }

        .smart-groups-filter-time {
            flex: 1 1 auto;
            height: 100%;
            border: none;
        }

        .toggle-sidebar {
            flex: 0 0 40px;
            width: 40px;
            height: 100%;
            border-top: 0;
            transition: border-color .2s ease;
            background: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(darken($border-color, 20%), 1);

            i {
                position: relative;
                left: -3px;
            }

            &:hover {
                cursor: pointer;
                color: $dark-grey;
            }

            &.showing-sidebar {
                border-bottom-color: red;
                i {
                    background-color: $white;
                }
            }
        }

        @media (max-width: $navbar-collapse-breakpoint) {
            .toggle-sidebar {
                display: none;
            }

            & > header {
                display: block;

            }
            & > header,
            & > .sidebar-content {
                border-left: 0;
            }

            .smart-groups-filter-time {
                float: right;
                height: 50px;
                border-bottom: $border;
                border-left: $border;
                width: 103px;

                .calendar-popup.active {
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
                }

                .smart-group-filter-time-panel {
                    border-left: 2px solid $border-color;
                    padding: 0 4px;

                    .calendar-popup-toggle-button {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-size: 11px;
                        background: none;
                        border: none;

                        .icon-calendar {
                            font-size: 14px;
                            margin-right: 6px;
                        }

                        .timerange {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                        }

                        .timerange-start::after {
                            display: none;
                        }

                        span {
                            font-size: 10px;
                            display: inline-block;
                        }
                    }
                }
            }
        }


        // This is the part below the time filter
        & > main {
            position: absolute;
            right: 0; left: 0; bottom: 0;
            top: $subheader-height;
            display: flex;
            flex-direction: column;
            flex-wrap: no-wrap;
            justify-content: center;
            align-content: center;
            border-left: 1px solid darken($border-color, 2%);
            z-index: 0;

            .smart-groups-panel {
                flex: 1 1 auto;
                align-self: stretch;
                background: $white;
                position: relative;
                transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
            }

            .query-modifiers {
                flex: 0 0 auto;
                align-self: stretch;
            }
        }

        // This is the Segments (smart groups) filter modal
        .smart-groups-filter-container {
            position: fixed;
            top: $topbar-height;
            right: $smart-group-panel-width;
            left: 0;
            bottom: 0;
            background-color: red;
        }
    }

    #system-message {
        height: $subheader-height;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background: $white;
        border-top: $border;
        color: $darker-grey;
        font-size: 13px;
        line-height: $subheader-height - 2px;
        padding: 0 10px;

        & ~ #view-container {
            bottom: $subheader-height;
        }

        .system-icon {
            font-size: 16px;
            position: relative;
            top: 2px;
        }
    }

    .hide-small {
        @media(max-width: $navbar-collapse-breakpoint) {
            display: none !important;
        }
    }
    .hide-large {
        @media(min-width: $navbar-collapse-breakpoint + 1) {
            display: none !important;
        }
    }

    @media (max-width: $navbar-collapse-breakpoint) {
        .modal {
            right: 0px;

            &.smart-groups-filter-container.compact {
                .smart-groups-filter.compact {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    header h1 {
                        display: flex;
                        align-items: center;
                        line-height: 16px;
                    }

                    main {
                        height: calc(100% - 50px);
                        width: 100%;
                        position: unset;
                    }

                    .smart-group-filter-items .property-filters {
                        top: 50px;
                        .item-property-filter  {
                            flex: unset;
                            width: 100%;
                        }
                    }

                    .item-property-filters {
                        position: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}


#app > footer {
    position: absolute;
    height: $footer-height;
    left:   0;
    bottom: 0;
    right:  0;
}
