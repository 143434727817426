@use 'sass:math';

$header-background-color: $navy;
$header-active-background-color: #2f3642;
$header-font-color: rgb(92, 100, 104);
$header-border-color: darken($header-background-color, 10%);
$header-active-color: lighten($yellow, 4%);
$header-active-color-reporting: $purple;

$organization-logo-size: math.div($topbar-height, 2);

$logo-width: $topbar-height;
$logo-height: $topbar-height;

#app.reporting > header {
    .header-logo {
        background-color: $header-active-color-reporting;
    }
    nav .report-select li {
        &.active,
        &:hover {
            * {
                color: $header-active-color-reporting;
            }
        }
    }
}

body.dashboard-suspended .support {
    display: none !important;
}

// used for elements that display only while loading
.fake {
    height: $topbar-height;
    background: $header-background-color;
    position: relative;

    .support {
        position: absolute;
        right: $smart-group-panel-width;
        padding-right: 17px;
        padding-left: 10px;
        line-height: $topbar-height;
        vertical-align: top;
        width: auto;
        color: #fdfdfd;
        display: inline-block;
        font-size: 10px;
        text-transform: uppercase;
        overflow: hidden;

        i {
            padding-right: 7px;
        }

        &:hover {
            color: $header-active-color;
            background-color: $header-active-background-color;
        }
    }
}

.header-logo {
    display: block;
    flex: 0 0 $logo-width;
    margin-right: 1px;
    height: $logo-height;
    width: $logo-width + 0.5px;
    border-radius: 4px 1px 0px 0px;
    overflow: hidden;
    background-color: $header-active-color;
    border-right: 0.5px solid $navy;
    border-bottom: 1px solid #00000030;
    &:hover {
        animation: rotate-36 0.5s infinite;
    }
    &:not(:hover) {
        animation: none;
        background-color: $header-active-color;
    }
}

.nav-multi-check-dropdown {
    position: relative;

    .nav-multi-check-dropdown-header {
        height: $topbar-height;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4px 0 12px;
        min-width: 120px;
        cursor: pointer;

        .title-icon {
            margin-right: 7px;
        }

        .nav-multi-check-dropdown-header-title {
            flex: 1;
            display: inline-block;
        }

        .icon-dropdown-toggle {
            flex: 0;
            position: relative;
            top: 2px;
            &:before {
                font-size: 16px;
            }
        }
        &:not(.opened) .icon-dropdown-toggle:before {
            content: '\e877';
        }
        &.opened .icon-dropdown-toggle:before {
            content: '\e87a';
        }

        &.opened {
            color: $yellow;
            background: #2f3642;
        }
    }

    .nav-multi-check-dropdown-options {
        position: absolute;
        top: $topbar-height;
        right: 0px;
        min-width: 120px;
        box-shadow: -10px 10px 20px -05px rgba(black, 0.2);
        border-radius: 0 0 4px 4px;
        overflow: hidden;
    }

    .nav-multi-check-dropdown-item {
        cursor: pointer;
        height: $topbar-height;
        line-height: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $navy;
        color: rgba($white, 0.8);
        border-left: 1px solid rgba(white, 0.15);
        border-top: 1px solid rgba(white, 0.15);
        padding: 0 14px;

        .nav-multi-check-dropdown-item-title {
            white-space: nowrap;
        }

        .nav-multi-check-dropdown-item-icon {
            margin-right: 6px;
            i {
                font-size: 12px;
            }
        }

        &.selected {
            color: $yellow;

            .checkbox-container {
                input[type='checkbox']::before {
                    border-color: $yellow;
                    height: 6px;
                }
            }
        }

        &:hover {
            color: $yellow;
            background: #2f3642;
        }

        .checkbox-container {
            display: flex;
            align-items: center;
            margin-right: 10px;

            input[type='checkbox'] {
                width: 12px;
                height: 12px;
            }

            input[type='checkbox']:checked {
                color: $yellow;
                background: $navy;
                border-color: $yellow;
            }
        }
    }
}


#app > header {
    font-weight: 600;
    letter-spacing: 1px;
    // overflow: hidden;

    .top-bar,
    .side-bar {
        background-color: $white;
        z-index: $z-header;
    }

    .logo {
        $height: $topbar-height * 0.6;
        position: relative;
        height: $logo-height;
        width: $logo-width;
        left: -0.5px;
        top: 0.5px;
        background-image: url('~images/42logo-black.png');
        background-size: $topbar-height * 0.5;
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
    }

    .collapses {
        display: flex;
        flex-direction: row;
        max-height: $topbar-height;
        // overflow: hidden;

        &.small {
            flex-direction: column;
            max-height: none;
        }

        &.hide {
            display: none;
        }
    }

    .top-bar {
        position: absolute;
        top: 0;
        left: 0;
        line-height: $topbar-height;
        background: $header-background-color;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fdfdfd;
        width: 100%;
        user-select: none;
        // overflow: hidden;

        .collapse-header {
            height: $topbar-height;
            display: flex;

            .icon-menu {
                vertical-align: top;
                text-align: center;
                height: $topbar-height;
                display: inline-block;
                font-size: 20px;
                opacity: 0.3;
                position: absolute;
                top: 0px;
                left: $topbar-height;
                right: $smart-group-panel-width;
                text-align: left;
                padding-left: 6px;
                &.active {
                    background: lighten($navy, 10%);
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .right {
            top: 0;
            margin-left: auto;
            margin-right: $smart-group-panel-width;

            @media (max-width: $navbar-collapse-breakpoint) {
                margin-right: auto;
                width: 100%;
            }

            & > * {
                text-align: center;
                float: right;
                font-size: 10px;
                letter-spacing: 1px;
                &:hover {
                    background: lighten($header-background-color, 5%) !important;
                    color: $header-active-color;
                }
                &:not(:hover) {
                    transition: background-color 0.4s;
                }
                a {
                    display: inline-block;
                    padding-left: 10px;
                    padding-right: 10px;
                    span {
                        margin-left: 7px;
                    }
                }
            }
        }

        &:not(.small) {
            @media (max-width: 1200px) {
                .nav-support .support.links,
                .nav-support .status-button {
                    > a {
                        padding-left: 16px;
                        padding-right: 16px;
                        span {
                            display: none;
                        }
                    }
                }
            }
        }

        .organization {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: $smart-group-panel-width;
            border-left: 1px solid rgba(white, 0.05);
            cursor: default;

            .organization-logo {
                position: absolute;
                left: 20px;
                top: math.div(($topbar-height - $organization-logo-size), 2);
                width: $organization-logo-size;
                height: $organization-logo-size;
                display: inline-block;
                border-radius: 100%;
                background: #444;
            }

            .organization-label {
                color: inherit;
                text-transform: uppercase;
                position: absolute;
                left: 20px + $organization-logo-size + 10px;
                white-space: pre;
                right: 0;
                line-height: $topbar-height;
                font-size: 10px;
                letter-spacing: 2px;
            }
        }

        & > ul {
            left: $logo-width;

            font-size: 0;

            & .page-select {
                flex-grow: 1;
                background: $header-background-color;

                li.active {
                    background: lighten($header-background-color, 8%) !important;
                }
            }

            li {
                position: relative;
                display: inline-block;
                text-align: center;

                font-size: 10px;
                letter-spacing: 1px;

                &:hover {
                    background-color: lighten($header-background-color, 5%) !important;
                }
                &:not(:hover) {
                    transition: background-color 0.4s;
                }

                a {
                    display: block;
                    padding: 0 20px;
                    overflow: hidden;
                    max-height: $topbar-height;

                    &:hover {
                        background: lighten($header-background-color, 5%) !important;
                        color: $header-active-color;
                    }

                    &.active {
                        color: $header-active-color;
                    }

                    i {
                        padding-right: 7px;
                    }
                }
            }
        }
    }
}
